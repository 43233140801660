* Botones y alineaciones */ .button {
    position: relative;
}

.button2 {
    float: right;
}

.EditButton {}

/* Input de comentarios */
.ImputComment {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
}

.ImputComment .InputButton {
    position: relative;
    width: 100px !important;
    display: inline-block;
}

.CommentButton {
    margin: 0 !important;
    border-radius: 0 5px 5px 0 !important;
    padding: 0 20px !important;
}

.ImputCommentEdit {
    width: calc(100% - 80px);
    display: inline-block;
}

.ItemSubtitleEdit {
    max-height: 150px;
}

.CommentEditButton {
    display: inline-block;
    width: 70px;
}

.CommentEditButton .button {
    width: 70px;
}

/* Botón de mostrar más */
.ShowMoreButton {
    display: block;
    float: right;
}

/* Contenedor de comentarios */
.CommentsUsers {
    margin-top: 1rem;
}

.Comments {
    text-align: left;
    font-family: var(--textFont);
}

.Comment2 {
    width: 100%;
    margin-top: 20px;
    margin-left: 2px;
}

/* Títulos y subtítulos */
.ItemTitle {
    font-family: var(--textFont);
    font-weight: bold;
    color: var(--textColor);
    display: inline-block;
    padding-right: 10px;
    font-size: 1.5rem;
}

.ItemSubtitle {
    font-family: var(--textFont);
    width: 100%;
}

.PublishDate {
    font-family: var(--textFont);
    font-size: xx-small;
    display: inline-block;
}

/* Imagen de usuario (actualmente desactivada) */
.UsuarioComent {
    width: 40px;
    height: 40px;
    display: none;
}

.AvatarComent {
    width: 40px;
    height: 40px;
    margin-top: 40px;
}

/* Barra superior del comentario */
.TopBarComment {
    width: 100%;
    position: relative;
    margin-bottom: 8px;
}

@media only screen and (max-width: 480px) {
    .ItemSubtitle {
        width: 100%;
    }
}

/* Estilos del contenedor de comentarios */
.CommentBox {
    background: #fff;
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 12px 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    transition: transform 0.2s ease-in-out;
}

.CommentBox:hover {
    transform: scale(1.02);
}

/* Cabecera del comentario */
.CommentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}

/* Nombre del usuario */
.Username {
    font-size: 18px;
    font-weight: bold;
    color: var(--titleColor);
    flex-grow: 1;
    /* Hace que el nombre ocupe más espacio */
}

/* Fecha del comentario */
.CommentDate {
    font-size: 12px;
    color: #888;
    white-space: nowrap;
    margin-left: 10px;
}

/* Ajustes específicos para móviles */
@media (max-width: 600px) {
    .CommentHeader {
        flex-direction: column;
        /* En móviles, coloca la fecha debajo */
        align-items: flex-start;
    }

    .CommentDate {
        margin-left: 0;
        /* Quita el margen en móviles */
        margin-top: 4px;
        /* Agrega un espacio entre el usuario y la fecha */
        font-size: 11px;
        /* Reduce la fuente en móviles */
    }
}

/* Texto del comentario */
.CommentText {
    font-size: 16px;
    color: #333;
    line-height: 1.4;
    margin-top: 6px;
}

/* Sección de edición de comentarios */
.EditCommentSection {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.EditCommentInput {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 6px;
}

.EditCommentButton {
    background: #007bff;
    color: #fff;
    padding: 6px 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
}

.EditCommentButton:hover {
    background: #0056b3;
}