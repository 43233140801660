#hightligthCarousel_v2 {
  overflow: hidden;
}
#hightligthCarousel_v2 .carousel-indicators {
  justify-content: flex-end !important;
  margin-right: 5% !important;
  margin-left: 50%;
}
#hightligthCarousel_v2 .carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--textColor);
  opacity: 1;
  max-width: initial;
}
.carousel-indicators button {
  box-sizing: revert-layer;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;
  opacity: .5;
  transition: opacity .6s ease;
}

#hightligthCarousel_v2 .carousel-indicators button {
border-radius: 50%;
background-color: var(--textColor);
opacity: 1;
margin-right: 3px; /* Ajusta el margen derecho */
margin-left: 3px;
border-width: 0;
height: 12px;
}
#hightligthCarousel_v2 .carousel-indicators .active {
transition: all 0.8s ease;
border-radius: 0% !important;
width: 32px;
background-color: var(--focusColor);
-webkit-border-radius: 8px;
-moz-border-radius: 8px;
-ms-border-radius: 8px;
-o-border-radius: 8px;
-webkit-transition: all 0.5s ease;
-moz-transition: all 0.5s ease;
-ms-transition: all 0.5s ease;
-o-transition: all 0.5s ease;
}
#hightligthCarousel_v2 .carousel-control-next {
  right: 8px;
  justify-content: flex-end;
}
#hightligthCarousel_v2 .carousel-control-prev {
  left: 8px;
  justify-content: flex-start;
}

#hightligthCarousel_v2 .carousel-control-next,
#hightligthCarousel_v2 .carousel-control-prev {
position: absolute;
display: flex;
top: 50%;
transform: translateY(-50%);
z-index: 999;
opacity: 1;
  width: 50px;
  height: 80px;
  background: none;
  align-items: center;
}

#hightligthCarousel_v2 .carousel-control-next svg,
#hightligthCarousel_v2 .carousel-control-prev svg {
  background-color: var(--arrowBackgroundColor, var(--defaultButtonColor));
  border-radius: 25%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
background-image: none !important;
background-color: var(--arrowBackgroundColor, var(--defaultButtonColor));
color: var(--defaultButtonTextColor);
display: flex;
justify-content: center;
align-items: center;
height: 50px;
padding: 0px 2px;
border-radius: 8px;
width: 40px;
}

.carousel-control-next span,
.carousel-control-prev span {
  display: none;
}

@media only screen and (max-width: 480px) {
  #hightligthCarousel_v2 .carousel-indicators {
    justify-content: center !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  #hightligthCarousel_v2 .carousel-item {
    transition-duration: 0.35s;
  }
}



